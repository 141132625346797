<!--
 * @Author: CL
 * @Date: 2021-06-08 22:45:38
 * @LastEditTime: 2021-07-22 10:54:52
 * @Description: 侧边导航组件
-->

<template>
  <div class="aside-contain">
    <div class="aside-avatar">
      <Avatar :url="url" :size="120"/>
      <div class="title">我们好像在哪见过</div>
    </div>
    <div class="aside-menu">
      <Menu 
        :activeIndex="activeIndex" 
        :menusArr="menusArr"
        background="#202020"
        @handleChange="handleChange"
      />
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar';
import Menu from '@/components/Menu';
import path from '@/assets/t2.jpg';

export default {
  data(){
    return {
      url: path,
      activeIndex: 0,
      menusArr: [
        {
          path: '/manage/articleback',
          title: '文章管理',
          type: 'blog',
          name: 'articleback',
          exact: false
        },
        {
          path: '/manage/adminback',
          title: '账号管理',
          type: 'login',
          name: 'adminback',
          exact: false
        },
        {
          path: '/manage/imgback',
          title: '图片鉴赏管理',
          type: 'img',
          name: 'imgback',
          exact: false
        },
        {
          path: '/manage/navback',
          title: '导航管理',
          type: 'nav',
          name: 'navback',
          exact: true
        },
        {
          path: '/manage/codeback',
          title: '代码&效果',
          type: 'code',
          name: 'codeback',
          exact: true
        },
        {
          path: '/manage/messageback',
          title: '留言管理',
          type: 'message2',
          name: 'messageback',
          exact: true
        },
        {
          path: '/manage/everydayback',
          title: '每日一句',
          type: 'everyday',
          name: 'everydayback',
          exact: true
        },
      ]
    }
  },

  methods: {
    handleChange(index){
      this.activeIndex = index;
    }
  },

  components: {
    Avatar,
    Menu,
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/var.less';

.aside-contain{
  width: 100%;
  height: 100%;
  background: @contactBg;
  padding: 20px 0;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;

  .aside-avatar > .title{
    width: 100%;
    color: white;
    text-align: center;
    font-size: 22px;
    padding: 25px 0 0 0;
    box-sizing: border-box;
  }

  .avatar-contain{
    margin: 0 auto;
  }

  .aside-menu{
    margin-top: 30px;
  }

  .aside-contact{
    margin-top: 30px;
  }
}
</style>
