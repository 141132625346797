<!--
 * @Author: CL
 * @Date: 2021-06-24 15:06:33
 * @LastEditTime: 2021-07-22 11:23:43
 * @Description: 每日一句和账号退出的功能组件
-->

<template>
  <div class="everyday-contain">
    <div class="everyday-wrap">
      <div class="everyday-content">
        <span>{{ word }}</span>
        <span>—— 曹磊</span>
      </div>
      <div class="user-info">
        <div>
          <span>小主人：</span>
          <span>{{ user? user.loginId : '' }}</span>
        </div>
        <el-button size="small" @click="handleGoHome">博客首页</el-button>
        <el-button size="small" @click="handleLogOut">退出登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  created(){
    this.$store.dispatch('everyDay/queryWords');
  },

  computed: {
    ...mapState('loginUser', ['user']),
    ...mapState('everyDay', ['word'])
  },

  methods: {
    /**
     * 退出登录
     */
    handleLogOut(){
      this.$utils.confirm('您确定要退出系统吗？').then(() => {
        this.$store.dispatch('loginUser/logOut').then(() => {
          this.$showMessage({
            type: 'success',
            message: '退出成功'
          })
        });
      }).catch(err => {
        //点击了取消
        this.$showMessage({
          type: 'info',
          message: '已取消退出'
        }); 
      })
    },

    /**
     * 返回到博客首页
     */
    handleGoHome(){
      this.$router.push({ name: 'home' });
    }
  }
}
</script>

<style lang="less" scoped>
.everyday-contain{
  width: 100%;
  box-sizing: border-box;
  padding: 5px 10px 0;
  height: 55px;
  flex-shrink: 0;

  .everyday-wrap{
    border-radius: 5px;
    height: 100%;
    padding-right: 15px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .everyday-content{
      letter-spacing: 2px;
      padding: 0 0 0 15px;
      display: flex;
      align-items: center;

      >span{
        &:nth-of-type(1){
          margin-right: 30px;
        }
      }
    }
  }
  
  .user-info{
    display: flex;
    align-items: center;

    >div{
      margin-right: 30px;
      
      >span{
        &:nth-of-type(1){
          font-size: 14px;
        }

        &:nth-of-type(2){
          font-weight: bold;
          font-size: 18px;
          background-image: -webkit-linear-gradient(left, #2c3e50,#27ae60,#2980b9,#0080c0, #408080 ,#8e44ad);
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          -webkit-background-size: 200% 100%;
          -webkit-animation: move 4s infinite linear;
        }
      }
    }
  }
}

@keyframes move {
  0%  { background-position: 0 0;}
  100% { background-position: -100% 0;}
}
</style>
