<!--
 * @Author: CL
 * @Date: 2021-06-22 14:21:08
 * @LastEditTime: 2021-07-28 09:02:05
 * @Description: 后台管理的首页
-->

<template>
  <div class="manage-contain">
    <Layout bgColor="rgba(250, 250, 250, 0.9)"> 
      <template v-slot:left>
        <div class="left">
          <Aside />
        </div>
      </template>
      <template v-slot:default>
        <EveryDay />
          <router-view></router-view>
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/components/Layout';
import Aside from '@/components/Aside';
import EveryDay from '@/components/EveryDay';

export default {
  components: {
    Layout,
    Aside,
    EveryDay
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/mixin.less';

.manage-contain{
  .self-fill(fixed);
  
  .left{
    width: 250px;
    height: 100%;
  }

  .top-nav{
    width: 100%;
    height: 30px;
    border: 1px solid red;
  }
}
</style>