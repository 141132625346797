<!--
 * @Author: CL
 * @Date: 2021-06-03 15:13:43
 * @LastEditTime: 2021-06-15 10:45:49
 * @Description: 菜单组件
 * 1. 菜单数据的数据格式
 * [ 
 *   { path: '', name: '', type: '' }
    path: 跳转路径
    name: 菜单名称
    type: icon类型
 * ]
 *
 * 2. background 菜单的背景颜色
 * 3. activeColor 高亮颜色
 * 4. activeIndex 活跃索引
 * 5. textColor 活跃的文字颜色
-->

<template>
  <div class="menu-contain" :style="{background: background}">
    <router-link 
      class="router-link" 
      v-for="(item, index) in menusArr" 
      :key="index"
      :to="{ name: item.name }"
      :class="{ 'active': activeIndex == index }"
      @click.native="handleChange(index)"
      :exact="item.exact"
      exact-active-class=""
      :style="activeIndex == index? { background: activeColor, color: textColor } : ''"
    >
      <IconFont :type="item.type" :size="16" />
      <span>{{ item.title }}</span>
    </router-link>
  </div>
</template>

<script>
import IconFont from '@/components/IconFont';

export default {
  components: {
    IconFont
  },

  methods: {
    /**
     * 菜单切换
     */
    handleChange(index){
      this.$emit('handleChange', index);
    }
  },

  props: {
    background: {
      type: String,
      default: 'black'
    },

    menusArr: {
      type: Array,
      required: true
    },

    activeIndex: {
      type: Number,
      required: true
    },

    activeColor: {
      type: String,
    },

    textColor: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/index.less';
@import '~@/styles/var.less';

.menu-contain{
  width: 100%;
  // max-width: 180px;
  border-radius: 5px;

  .router-link{
    display: flex;
    align-items: center;
    padding: 12px 0 12px 40px;
    color: @menutext;

    &.router-link-active{
      background: rgba(165, 46, 132, 0.3);
      color: white;
    }

    span{
      font-size: 14px;
      margin-left: 8px;
      display: inline-block;
      align-self: center;
    }
  }
}
</style>
