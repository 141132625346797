<!--
 * @Author: CL
 * @Date: 2021-06-09 23:04:26
 * @LastEditTime: 2021-06-24 15:14:47
 * @Description: 布局组件，具名插槽
-->

<template>
  <div class="layout-contain">
    <div class="left">
      <slot name="left"></slot>
    </div>
    <div class="main" :style="{ backgroundColor: bgColor }">
      <slot></slot>
    </div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style lang="less" scoped>
.layout-contain{
  width: 100%;
  height: 100%;
  display: flex;

  .left, .right{
    flex: 0 0 auto;  //不压缩也不拉伸
    overflow: hidden;
  }

  .main{
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}
</style>
